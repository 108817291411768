import { useState } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import styled from "styled-components"

import { ErrorAlert } from "src/components/Account/BillingPortal/ErrorAlert"
import Responder from "src/components/ResponseService/images/responder-image.jpeg"
import { breakpoint } from "src/constants/breakpoints"
import {
  HREF_MINUT_GUARD_ASSIST,
  HREF_MINUT_REGISTER_GUARD_ASSIST,
} from "src/constants/hrefs"
import { usePostTrackGuardAssistEvents } from "src/data/analytics/queries/guardAssistAnalyticsQueries"
import { useFetchResponseServiceProviders } from "src/data/homes/queries/responseServiceQueries"
import { IResponseServiceProvidersResponse } from "src/data/homes/types/responseServiceTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useEffectOnce } from "src/hooks/useEffectOnce"
import { useFlags } from "src/hooks/useFlags"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { BackButton } from "src/ui/BackButton/BackButton"
import { mColors } from "src/ui/colors"
import { MDialog } from "src/ui/Dialog/MDialog"
import ClockIcon from "src/ui/icons/clock.svg"
import PinIcon from "src/ui/icons/pin.svg"
import ResponseServiceIcon from "src/ui/icons/response-service.svg"
import { MainView } from "src/ui/Layout/MainView"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { LearnMore } from "src/ui/Link/LearnMore"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { formatPrice } from "src/utils/formatPrice"

import "react-lazy-load-image-component/src/effects/opacity.css"

export function ResponseServiceEmptyState() {
  const { t, langKeys } = useTranslate()
  const user = useGetUser()
  const { org } = useOrganization()
  const fetchResponseServiceProviders = useFetchResponseServiceProviders()
  const { alpha } = useFlags()

  // Analytics to track the difference between the empty state and the activated state
  const postTrackLandingPageEmptyState = usePostTrackGuardAssistEvents(
    "Guard Assist Landing Page EmptyState"
  )

  useEffectOnce(() => {
    postTrackLandingPageEmptyState.mutate({
      userId: user.user_id,
      orgId: org.id,
    })
  })

  function LoadingState() {
    return (
      <CardGrid>
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
      </CardGrid>
    )
  }

  function ProviderCards() {
    const { data } = fetchResponseServiceProviders
    if (!data) {
      return <ErrorAlert />
    }

    return (
      <CardGrid>
        {data.map((cardData, index) => (
          // Combine id and index since id is not unique
          <ProviderCard data={cardData} key={`${cardData.id}-${index}`} />
        ))}
      </CardGrid>
    )
  }

  return (
    <MainView
      title={t(langKeys.response_service)}
      titleBarProps={{
        description: t(langKeys.response_service_description),
        breadcrumbs: alpha ? (
          <BackButton to={Routes.AssistServices.location()}>
            {t(langKeys.back)}
          </BackButton>
        ) : null,
      }}
    >
      <HowItWorksSection>
        <ResponderImageBox>
          <ResponderImage src={Responder} alt="responder" effect="opacity" />
        </ResponderImageBox>

        <HowItWorksText>
          <MText variant="heading2" marginBottom={spacing.M}>
            {t(langKeys.response_service_responder_title)}
          </MText>

          <Ol>
            <Li>{t(langKeys.response_service_responder_body_1)}</Li>
            <Li>{t(langKeys.response_service_responder_body_2)}</Li>
            <Li>{t(langKeys.response_service_responder_body_3)}</Li>
          </Ol>
          <LearnMore href={HREF_MINUT_GUARD_ASSIST} />
        </HowItWorksText>
      </HowItWorksSection>

      <MText variant="heading2">
        {t(langKeys.placeholder_web, {
          text: "Select one of our security partners to get started",
        })}
      </MText>

      {fetchResponseServiceProviders.isLoading ? (
        <LoadingState />
      ) : (
        <ProviderCards />
      )}
      <MText variant="body">
        {t(langKeys.placeholder_web, { text: "Don’t see your location?" })}{" "}
        <ExternalLink href={HREF_MINUT_REGISTER_GUARD_ASSIST}>
          {t(langKeys.placeholder_web, { text: "Register interest here." })}
        </ExternalLink>
      </MText>
    </MainView>
  )
}

function ProviderCard({
  data,
}: {
  data: IResponseServiceProvidersResponse[number]
}) {
  const [showDialog, setShowDialog] = useState(false)
  const { t, langKeys } = useTranslate()
  const { navigate } = useRouter()
  const user = useGetUser()
  const { org } = useOrganization()
  const postTrackGuardAssistProviderSelected = usePostTrackGuardAssistEvents(
    "Guard Assist Provider Selected"
  )

  return (
    <Card
      onClick={() => {
        setShowDialog(true)
        postTrackGuardAssistProviderSelected.mutate({
          userId: user.user_id,
          orgId: org.id,
          providerId: data.id,
        })
      }}
    >
      <ImageBox>
        <Map
          className="map"
          src={data.images.map}
          alt="map"
          style={{ width: "100%", height: "100%" }}
        />
        <IconBox>
          {data.images.icon ? (
            <img
              src={data.images.icon}
              alt="provider-icon"
              style={{ width: "24px", height: "24px" }}
            />
          ) : (
            <ResponseServiceIcon width="24px" />
          )}
        </IconBox>
      </ImageBox>
      <MText variant="body">{data.description_details.subtitle}</MText>
      <MText variant="heading3">{data.description_details.title}</MText>
      <MText variant="body">
        <strong>
          {formatPrice(
            data.billing.price_per_unit / 100,
            data.billing.currency
          )}
        </strong>
        /month/{t(langKeys.home).toLowerCase()}
      </MText>
      <MText variant="bodyS">
        <strong>
          +
          {formatPrice(data.billing.callout_price / 100, data.billing.currency)}
        </strong>
        /callout
      </MText>

      {showDialog && (
        <MDialog
          onClose={() => setShowDialog(false)}
          confirmLabel={t(langKeys.placeholder_web, { text: "Add partner" })}
          onConfirm={() => {
            setShowDialog(false)
            navigate(
              Routes.ActivateResponseService.location({ provider: data.id })
            )
          }}
          open={showDialog}
          title={data.description_details.title}
        >
          <DialogBox>
            <DescriptionBox>
              <DescriptionRow>
                <ClockIcon width="18px" />
                {data.description_details.availability}
              </DescriptionRow>

              <DescriptionRow>
                <PinIcon width="18px" />
                {data.description_details.location}
              </DescriptionRow>

              <MText variant="body">
                {data.description_details.description}
                {data.description_details.learn_more && (
                  <ExternalLink href={data.description_details.learn_more}>
                    {" "}
                    {t(langKeys.learn_more)}
                  </ExternalLink>
                )}
              </MText>
              <MText variant="body">
                <strong>
                  {formatPrice(
                    data.billing.price_per_unit / 100,
                    data.billing.currency
                  )}
                </strong>
                /month/{t(langKeys.home).toLowerCase()} (
                <strong>
                  +
                  {formatPrice(
                    data.billing.callout_price / 100,
                    data.billing.currency
                  )}
                </strong>
                /callout)
              </MText>
            </DescriptionBox>

            <img src={data.images.map} alt="map" />
          </DialogBox>
        </MDialog>
      )}
    </Card>
  )
}

function SkeletonCard() {
  return (
    <div>
      <MSkeleton variant="rect" width="100%" style={{ paddingTop: "100%" }} />
      <MSkeleton variant="text" />
      <MSkeleton variant="text" />
      <MSkeleton variant="text" width="60%" />
      <MSkeleton variant="text" width="60%" />
    </div>
  )
}

// The map image is designed for 16.25 thus that is the minimum, I added 1 rem
// on each side to create a nicer border on focus and hover states
const MIN_CARD_SIZE = "18.25rem"

const CardGrid = styled.div`
  display: grid;
  margin-top: ${spacing.L};
  margin-bottom: ${spacing.L};
  grid-template-columns: repeat(auto-fit, minmax(${MIN_CARD_SIZE}, 1fr));
  gap: ${spacing.XS};
`

const IconBox = styled.div`
  position: absolute;
  bottom: ${spacing.M};
  left: ${spacing.M};
  padding: ${spacing.M};
  background: white;
  border-radius: 8px;
`

const ImageBox = styled.div`
  margin-bottom: ${spacing.L};
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  background: ${mColors.accentPrimaryLight};
`

const Map = styled.img`
  overflow: hidden;
  transition: transform 0.3s ease;
  max-height: 200px;
`

const Card = styled.button`
  all: unset;
  padding: ${spacing.M};
  border-radius: 8px;
  &:focus-visible,
  &:hover {
    cursor: pointer;
    box-shadow:
      0px -2px 4px 0px rgba(0, 0, 0, 0.06),
      0px 2px 4px 0px rgba(0, 0, 0, 0.06);
    div > .map {
      transform: scale(1.1);
    }
  }
`

const DescriptionRow = styled.div`
  display: flex;
  gap: ${spacing.XS};
  align-items: center;
`
const DescriptionBox = styled.div`
  display: grid;
  gap: ${spacing.S};
`

const DialogBox = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media (${breakpoint.mediumUp}) {
    flex-direction: row;
  }
  & > img {
    max-height: 400px;
  }
`

const ResponderImage = styled(LazyLoadImage)`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const ResponderImageBox = styled.div`
  width: 100%;
  flex-basis: 300px;
`

const HowItWorksSection = styled.section`
  display: flex;
  flex-wrap: wrap;

  gap: ${spacing.XL2} ${spacing.XL4};
  margin-bottom: ${spacing.XL2};
`

const HowItWorksText = styled.div`
  align-self: center;
  flex: 0 1 650px;
`

const Ol = styled.ol`
  padding-inline-start: 1rem;
`
const Li = styled.li``
