import { useMemo } from "react"
import styled from "styled-components"

import { CircularProgress } from "@material-ui/core"

import { Pager } from "src/components/Pager/Pager"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import { EnabledHomesListMoreButton } from "src/components/ResponseService/EnabledHomesListMoreButton"
import { useFetchDevices } from "src/data/devices/queries/deviceQueries"
import { TDevice } from "src/data/devices/types/deviceTypes"
import {
  useFetchResponseServiceProvider,
  usePatchResponseService,
} from "src/data/homes/queries/responseServiceQueries"
import {
  TDisturbanceMonitoringActive,
  THome as THomeBase,
} from "src/data/homes/types/homeTypes"
import { ResponseServiceSubscriptionStatus } from "src/data/homes/types/responseServiceTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import {
  colorsLegacy,
  mColors,
  systemInfoBackground,
  systemInfoForeground,
  systemOkBackground,
  systemOkForeground,
  systemWarningBackground,
  systemWarningForeground,
} from "src/ui/colors"
import { GridTable } from "src/ui/GridTable/GridTable"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MCardInteractive } from "src/ui/MCard/MCardInteractive"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { ErrorService } from "src/utils/ErrorService"
import { unique } from "src/utils/genericUtil"
import { formatDate } from "src/utils/l10n"
import { Maybe } from "src/utils/tsUtil"

type THome = Pick<
  THomeBase,
  | "home_id"
  | "organization_id"
  | "name"
  | "disturbance_monitoring"
  | "response_service"
>

export function EnabledHomesList({
  homes,
  homesCount,
}: {
  homes: THome[]
  homesCount: number
}) {
  const { org } = useOrganization()

  const { t, langKeys } = useTranslate()
  const { limit, setOffset } = useUrlPager()
  const patchResponseService = usePatchResponseService()

  const fetchDevices = useFetchDevices({
    orgId: org.id,
    filter: {
      home_ids: unique(homes.map((h) => h.home_id)),
    },
    options: { enabled: !!homes.length },
  })
  const homeDevices = fetchDevices.data?.devices ?? null

  const enabledHomesTableRows = useMemo(() => {
    return homes.map((home) => {
      const nbrHomeDevices = homeDevices?.filter(
        (d) => d.home === home.home_id
      ).length

      return (
        <>
          <MText variant="subtitle" key={home.home_id}>
            <InternalLink to={Routes.Home.location(home.home_id)}>
              {home.name}
            </InternalLink>
          </MText>
          <div>
            <Status
              subscriptionStatus={home.response_service?.subscription_status}
              disturbanceMonitoringActive={
                home?.disturbance_monitoring?.disturbance_monitoring_active
              }
              numberOfDevices={nbrHomeDevices}
            />
          </div>
          <div>
            {home.response_service?.subscription_status === "non_renewing" &&
              home.response_service?.current_term_end &&
              t(langKeys.response_service_plan_end, {
                date: formatDate({
                  date: home.response_service.current_term_end,
                  excludeTime: true,
                }),
              })}
          </div>
          <div>
            <DispatchStatus
              enabled={!home.response_service?.automatic_dispatch_disabled}
            />
          </div>
          <ButtonBox>
            <EnabledHomesListMoreButton
              homeId={home.home_id}
              provider={home.response_service?.provider}
              autoDispatchEnabled={
                !home.response_service?.automatic_dispatch_disabled
              }
              subscriptionStatus={home.response_service?.subscription_status}
            />
          </ButtonBox>
        </>
      )
    })
  }, [homeDevices, homes, langKeys.response_service_plan_end, t])

  const activeProvider = homes.find((h) => h.response_service?.active === true)
    ?.response_service?.provider

  const fetchResponseServiceProvider = useFetchResponseServiceProvider({
    providerId: activeProvider || "",
    options: {
      enabled: !!activeProvider,
    },
  })

  const enabledHomesResponsiveRows = useMemo(() => {
    return homes.map((home) => {
      return (
        <MobileRow key={home.home_id} home={home} homeDevices={homeDevices} />
      )
    })
  }, [homeDevices, homes])

  return (
    <EnabledHomesGrid>
      <div>
        <GridTable
          loading={patchResponseService.isLoading}
          header={[
            <div key={"home"}>{t(langKeys.home)}</div>,
            <div key={"status"}>{t(langKeys.status)}</div>,
            <div key={"misc"}></div>,
            <div key={"guard-assist"}>
              {t(langKeys.guard_assist_automatic_callout)}
            </div>,
            <div key={"misc2"}></div>,
          ]}
          templateColumns={"1fr 1fr 1fr 1fr auto"}
          rows={enabledHomesTableRows}
          mobileRows={enabledHomesResponsiveRows}
          responsiveModeTitle={t(langKeys.home, { count: 2 })}
        />
        <PagerWrapper>
          <Pager
            limit={limit}
            offset={0}
            setOffset={setOffset}
            totalCount={homesCount}
          />
        </PagerWrapper>
      </div>
      {activeProvider && (
        <div>
          <MCardInteractive>
            {fetchResponseServiceProvider.isInitialLoading ||
            !fetchResponseServiceProvider.data ? (
              <CircularProgress />
            ) : (
              <>
                <MText variant="bodyS" color="secondary">
                  {t(langKeys.placeholder_web, { text: "Powered by" })}
                </MText>
                <MText variant="heading2" marginBottom={spacing.M}>
                  {fetchResponseServiceProvider.data.images.icon && (
                    <Icon
                      src={fetchResponseServiceProvider.data.images.icon}
                      alt="provider-icon"
                    />
                  )}
                  {fetchResponseServiceProvider.data.description_details.title}
                </MText>
                <MText variant="bodyS" color="secondary">
                  {t(langKeys.placeholder_web, {
                    text: "Our guard service is provided by security partners. Questions about a recent callout?",
                  })}{" "}
                  <ExternalLink
                    href={
                      fetchResponseServiceProvider.data.contact_details.value
                    }
                  >
                    {t(langKeys.placeholder_web, {
                      text: "Here’s how to contact them.",
                    })}
                  </ExternalLink>
                </MText>
              </>
            )}
          </MCardInteractive>
        </div>
      )}
    </EnabledHomesGrid>
  )
}

function MobileRow({
  home,
  homeDevices,
}: {
  home: THome
  homeDevices: Maybe<TDevice[]>
}) {
  const { t, langKeys } = useTranslate()

  const nbrHomeDevices = homeDevices?.filter(
    (d) => d.home === home.home_id
  ).length

  return (
    <MobileRowGrid>
      <div>
        <InternalLink to={Routes.Home.location(home.home_id)}>
          {home.name}
        </InternalLink>

        <MuiButtonMobilePadding>
          <EnabledHomesListMoreButton
            homeId={home.home_id}
            provider={home.response_service?.provider}
            autoDispatchEnabled={
              !home.response_service?.automatic_dispatch_disabled
            }
            subscriptionStatus={home.response_service?.subscription_status}
          />
        </MuiButtonMobilePadding>
      </div>

      <div>
        <div>{t(langKeys.guard_assist_automatic_callout)}</div>

        <DispatchStatus
          enabled={!home.response_service?.automatic_dispatch_disabled}
        />
      </div>

      <div>
        <div>{t(langKeys.status)}</div>

        <Status
          subscriptionStatus={home.response_service?.subscription_status}
          disturbanceMonitoringActive={
            home?.disturbance_monitoring?.disturbance_monitoring_active
          }
          numberOfDevices={nbrHomeDevices}
        />
      </div>
    </MobileRowGrid>
  )
}

const MobileRowGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-auto-rows: 1fr; // ensure all rows have the same height
  grid-gap: ${spacing.M};

  padding-bottom: ${spacing.M};
  border-bottom: 1px solid ${colorsLegacy.backgroundGray};

  /* Handle grid items: */
  align-items: center;
  & > div {
    display: contents;
    & > *:last-child {
      place-self: end; // right-align second column
    }
  }
`

const MuiButtonMobilePadding = styled.div`
  & .MuiIconButton-root {
    padding: 2px; // force mobile padding on MUI button
  }
`

function DispatchStatus({ enabled }: { enabled: boolean }) {
  const { t, langKeys } = useTranslate()

  if (enabled) {
    return (
      <StatusBox
        $color={mColors.systemInfoDark}
        $background={mColors.systemInfoLight}
      >
        {t(langKeys.on)}
      </StatusBox>
    )
  }

  return (
    <StatusBox $color={mColors.textSecondary} $background={mColors.neutral}>
      {t(langKeys.off)}
    </StatusBox>
  )
}

function Status({
  subscriptionStatus,
  disturbanceMonitoringActive,
  numberOfDevices,
}: {
  subscriptionStatus: ResponseServiceSubscriptionStatus | undefined
  disturbanceMonitoringActive: TDisturbanceMonitoringActive | undefined
  numberOfDevices: number | undefined
}) {
  const { t, langKeys } = useTranslate()

  if (disturbanceMonitoringActive) {
    switch (subscriptionStatus) {
      case "active":
      case "non_renewing":
      case "in_trial":
        return (
          <StatusBox
            $color={systemOkForeground}
            $background={systemOkBackground}
          >
            {t(langKeys.active)}
          </StatusBox>
        )
      case "cancelled":
        return (
          <StatusBox
            $color={systemWarningForeground}
            $background={systemWarningBackground}
          >
            {t(langKeys.cancelled)}
          </StatusBox>
        )
      case "none":
        return (
          <StatusBox
            $color={systemInfoForeground}
            $background={systemInfoBackground}
          >
            {t(langKeys.none)}
          </StatusBox>
        )
      case undefined:
        ErrorService.captureMessage(
          "No reponse service subscription status on home"
        )
        return null
      default:
        ErrorService.captureMessage(
          `Unhandled subsciptionStatus ${subscriptionStatus}`
        )
        return null
    }
  }

  if (!numberOfDevices) {
    // loading state
    return <div>{t(langKeys.response_service_status_no_sensors)}</div>
  }

  return (
    <div>
      {numberOfDevices <= 0
        ? t(langKeys.response_service_status_no_sensors)
        : t(langKeys.response_service_status_noise_monitoring_off)}
    </div>
  )
}

const StatusBox = styled.div<{ $color: string; $background: string }>`
  inline-size: fit-content;
  padding: 0rem 0.5rem;
  background: ${({ $background }) => $background};
  color: ${({ $color }) => $color};
  border-radius: 16px;
`

const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
`

const EnabledHomesGrid = styled.div`
  display: grid;
  gap: ${spacing.L};
  grid-template-columns: 1fr 17.5rem;

  @media (max-width: 1310px) {
    display: flex;
    flex-direction: column-reverse;
  }
`

const Icon = styled.img`
  margin-right: ${spacing.S};
`

const PagerWrapper = styled.div`
  margin-block: ${spacing.XL};
`
